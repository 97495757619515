const imgsGallery = [
    {
        id: 1,
        url: '/gallery/gallery-1.jpg',
    },
    {
        id: 2,
        url: '/gallery/gallery-2.jpg',
    },
    {
        id: 3,
        url: '/gallery/gallery-3.jpg',
    },
    {
        id: 4,
        url: '/gallery/gallery-4.jpg',
    },
    {
        id: 5,
        url: '/gallery/gallery-5.png',
    },
    {
        id: 6,
        url: '/gallery/gallery-6.jpg',
    },
    {
        id: 7,
        url: '/gallery/gallery-7.jpg',
    },
    {
        id: 8,
        url: '/gallery/gallery-8.jpg',
    },
    {
        id: 9,
        url: '/gallery/gallery-9.jpg',
    },
    {
        id: 10,
        url: '/gallery/gallery-10.jpg',
    },
    {
        id: 11,
        url: '/gallery/gallery-11.jpg',
    },
    {
        id: 12,
        url: '/gallery/gallery-12.png',
    },
    {
        id: 13,
        url: '/gallery/gallery-13.jpg',
    },
    {
        id: 14,
        url: '/gallery/gallery-14.jpg',
    },
    {
        id: 15,
        url: '/gallery/gallery-15.jpg',
    },
    {
        id: 16,
        url: '/gallery/gallery-16.jpg',
    },
    {
        id: 17,
        url: '/gallery/gallery-17.jpg',
    },
    {
        id: 18,
        url: '/gallery/gallery-18.jpg',
    },
    {
        id: 19,
        url: '/gallery/gallery-19.jpg',
    },
    {
        id: 20,
        url: '/gallery/gallery-20.jpg',
    },    
    {
        id: 21,
        url: '/gallery/gallery-21.jpg',
    },
    {
        id: 22,
        url: '/gallery/gallery-22.jpg',
    },
    {
        id: 23,
        url: '/gallery/gallery-23.jpg',
    },
    {
        id: 24,
        url: '/gallery/gallery-24.jpg',
    }
]

export {imgsGallery} ;